export const tabsNav = {
    auction_house: {
        id: 'auction_house',
        name: 'Профиль',
        template: 'auction_house',
        pathName: 'auction_house',
        icon: 'store',
        iconSize: '16',
    },
    auction_house_catalogs: {
        id: 'auction_house_catalogs',
        name: 'Каталоги',
        template: 'auction_house_catalogs',
        pathName: 'auction_house_catalogs',
        icon: 'book',
        iconSize: '16',
    },
    auction_house_lots: {
        id: 'auction_house_lots',
        name: 'Лоты',
        template: 'auction_house_lots',
        pathName: 'auction_house_lots',
        icon: 'pencil',
        iconSize: '16',
    },
    auction_house_approved_users: {
        id: 'auction_house_approved_users',
        name: 'Одобренные пользователи',
        template: 'auction_house_approved_users',
        pathName: 'auction_house_approved_users',
        icon: 'user',
        iconSize: '16',
    },
    auction_house_orders: {
        id: 'auction_house_orders',
        name: 'Заказы',
        template: 'auction_house_orders',
        pathName: 'auction_house_orders',
        icon: 'bank',
        iconSize: '16',
    },
    auction_house_users: {
        id: 'auction_house_users',
        name: 'Пользователи админпанели',
        template: 'auction_house_users',
        pathName: 'auction_house_users',
        icon: 'user',
        iconSize: '16',
    },
    auction_house_balance: {
        id: 'auction_house_balance',
        name: 'Баланс',
        template: 'auction_house_balance',
        pathName: 'auction_house_balance',
        icon: 'bank',
        iconSize: '16',
    },
};
